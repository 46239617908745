import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment';
// import echarts from 'echarts'
import VueClipBoard from 'vue-clipboard2'
import * as $echarts from "echarts";
import "@/plugin/element"
import "normalize.css"
import "element-ui/packages/theme-chalk/src/index.scss";
import "element-ui/lib/theme-chalk/icon.css";
import http from '@/api/http.js'
import Print from 'vue-print-nb'
import "@/util/tianditu.js"
Vue.use(Print)

import Storage from 'vue-ls'
let options = {
  namespace: 'hiPrint-',
  name: 'ls',
  storage: 'local',
};
Vue.use(Storage, options);

Vue.prototype.$http = http
Vue.prototype.$echarts = $echarts
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueClipBoard)


//在出现报错的el-radio-group组件上绑定自定义指令
Vue.use(
  Vue.directive('removeAriaHidden', {
    bind(el, binding) {
      //获取节点
      let ariaEls = el.querySelectorAll('.el-radio__original');
      ariaEls.forEach(item => {
        item.removeAttribute('aria-hidden')
      });
    }
  })
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
