<template>
  <el-aside width="auto">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
      active-text-color="#2cb167"
    >
      <el-menu-item index="/home/index">
        <div class="icon-home"></div>
        <span slot="title">数据总览</span>
      </el-menu-item>
      <!-- 
        判断：
        0.requiresAuthAllq 且 roleType:2 或 3 时 (区域管理员和员工端都不展示该菜单)
        1.requiresAuth:true 且 roleType:3 是员工不显示该菜单
        2.requiresAffairs:true 且 roleType:4 是财务不显示该菜单
        3.open_pick == 2 且 item.path == '/sorting' 总管理端把该账号分拣功能关闭了（是否开启分拣功能：1-开启；2-关闭）
        4.isShow 设置帮助指到在菜单中不展示
      -->
      <el-submenu
        v-for="item in routes"
        :key="item.name"
        :index="item.path"
        :class="(item.meta.requiresAuthAll && (roleType == 2 || roleType == 3)) || (item.meta.requiresAuth && roleType == 3) || (item.meta.requiresAffairs && roleType == 4) || (open_pick == 2 && item.path == '/sorting') || item.meta.isShow == false ? 'display' : ''">
        <template>
          <template slot="title">
            <div :class="'icon-'+ (item.path).substr(1)" ></div>
            <span>{{ item.meta.title }}</span>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <el-menu-item
                :key="child.name"
                :index="child.path"
                :class="(child.meta.requiresAuthAll && (roleType == 2 || roleType == 3)) || (child.meta.requiresAuth && roleType == 3) || (child.meta.requiresAffairs && roleType == 4) || child.meta.isShow == false ? 'display' : ''? 'display' : ''"
              >{{ child.meta.title }}
              </el-menu-item>
            </template>
          </el-menu-item-group>
        </template>
      </el-submenu>
    </el-menu>

  </el-aside>
</template>
<script>
import {getStorage} from '@/storage'
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      routes: [],
      roleType: getStorage('role_type'),  // 1:超级管理员，2:管理员, 3：员工, 4：财务
      open_pick: getStorage('open_pick') || 1,  // 是否开启分拣功能：1-开启；2-关闭
    }
  },
  mounted() {
    this.routes = this.$router.options.routes.slice(3, this.$router.options.routes.length -1)
  }
}
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu {
  border: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  border-right: solid 1px #D3F3E1;
}
.el-menu-item.is-active {
  background: #eaf7f0;
}
.display {
  display: none !important;
}

</style>

<style>
.el-submenu.is-active > .el-submenu__title{
  color: #2CB167 !important;
}
.el-submenu.is-active > .el-submenu__icon-arrow{
  color: #2CB167 !important;
}
.icon-home {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i1.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-home {
  background-image: url('~@/assets/img/nav/i1-.png');
}
.icon-goods {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i2.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-goods {
  background-image: url('~@/assets/img/nav/i2-.png');
}
.icon-order {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i3.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-order {
  background-image: url('~@/assets/img/nav/i3-.png');
}
.icon-store {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i4.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-store {
  background-image: url('~@/assets/img/nav/i4-.png');
}
.icon-send {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i5.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-send {
  background-image: url('~@/assets/img/nav/i5-.png');
}
.icon-purchase {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i5.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-purchase {
  background-image: url('~@/assets/img/nav/i5-.png');
}
.icon-supplychain {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i5.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-supplychain {
  background-image: url('~@/assets/img/nav/i5-.png');
}
.icon-user {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i6.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-user {
  background-image: url('~@/assets/img/nav/i6-.png');
}
.icon-account {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i7.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-account {
  background-image: url('~@/assets/img/nav/i7-.png');
}
.icon-sorting {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i7.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-sorting {
  background-image: url('~@/assets/img/nav/i7-.png');
}
.icon-finance {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i3.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-finance {
  background-image: url('~@/assets/img/nav/i3-.png');
}
.icon-banner {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i3.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-banner {
  background-image: url('~@/assets/img/nav/i3-.png');
}
.icon-novice {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/i3.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-novice {
  background-image: url('~@/assets/img/nav/i3-.png');
}

</style>
